// Based mostly on https://github.com/Uniswap/interface/blob/main/src/theme/index.tsx

export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  gray50: '#F5F6FC',
  gray100: '#E8ECFB',
  gray150: '#D2D9EE',
  gray200: '#B8C0DC',
  gray250: '#A6AFCA',
  gray300: '#98A1C0',
  gray350: '#888FAB',
  gray400: '#7780A0',
  gray450: '#6B7594',
  gray500: '#5D6785',
  gray550: '#505A78',
  gray600: '#404A67',
  gray650: '#333D59',
  gray700: '#293249',
  gray750: '#1B2236',
  gray800: '#131A2A',
  gray850: '#0E1524',
  gray900: '#0D111C',
  gray950: '#080B11',
  pink50: '#F9ECF1',
  pink100: '#FFD9E4',
  pink200: '#FBA4C0',
  pink300: '#FF6FA3',
  pink400: '#FB118E',
  pink500: '#C41969',
  pink600: '#8C0F49',
  pink700: '#55072A',
  pink800: '#350318',
  pink900: '#2B000B',
  pinkVibrant: '#F51A70',
  red50: '#FAECEA',
  red100: '#FED5CF',
  red200: '#FEA79B',
  red300: '#FD766B',
  red400: '#FA2B39',
  red500: '#C4292F',
  red600: '#891E20',
  red700: '#530F0F',
  red800: '#380A03',
  red900: '#240800',
  redVibrant: '#F14544',
  yellow50: '#F6F2D5',
  yellow100: '#DBBC19',
  yellow200: '#DBBC19',
  yellow300: '#BB9F13',
  yellow400: '#A08116',
  yellow500: '#866311',
  yellow600: '#5D4204',
  yellow700: '#3E2B04',
  yellow800: '#231902',
  yellow900: '#180F02',
  yellowVibrant: '#FAF40A',
  // TODO: add gold 50-900
  gold200: '#EEB317',
  gold400: '#B17900',
  goldVibrant: '#FEB239',
  green50: '#E6FFF2',
  green100: '#B3FFD9',
  green200: '#80FFB3',
  green300: '#4DFFA6',
  green400: '#1AFF8C',
  green500: '#00E673',
  green600: '#00B359',
  green700: '#008040',
  green800: '#004D26',
  green900: '#001A0D',
  greenVibrant: '#33FF99',
  blue50: '#EDEFF8',
  blue100: '#DEE1FF',
  blue200: '#ADBCFF',
  blue300: '#869EFF',
  blue400: '#4C82FB',
  blue500: '#1267D6',
  blue600: '#1D4294',
  blue700: '#09265E',
  blue800: '#0B193F',
  blue900: '#040E34',
  blueVibrant: '#587BFF',
  // TODO: add magenta 50-900
  magenta300: '#FD82FF',
  magentaVibrant: '#FC72FF',
  purple300: '#8440F2',
  purple900: '#1C0337',
  purpleVibrant: '#6100FF',
  // TODO: add all other vibrant variations
  networkEthereum: '#627EEA',
  networkEthereumSoft: 'rgba(98, 126, 234, 0.05)',
  networkClassic: '#33FF99',
  networkClassicSoft: 'rgba(51, 255, 153, 0.05)',
  networkArbitrum: '#28A0F0',
  networkArbitrumSoft: 'rgba(40, 160, 240, 0.05)',
  networkAvalanche: '#E84142',
  networkAvalancheSoft: 'rgba(232, 65, 66, 0.05)',
  networkBase: '#0052FF',
  networkBaseSoft: 'rgba(0, 82, 255, 0.05)',
  networkBsc: '#F0B90B',
  networkBscSoft: 'rgba(240, 185, 11, 0.05)',
  networkCelo: '#FCFF52',
  networkCeloSoft: 'rgba(252, 255, 82, 0.05)',
  networkOptimism: '#FF0420',
  networkOptimismSoft: 'rgba(255, 4, 32, 0.05)',
  networkPolygon: '#A457FF',
  networkPolygonSoft: 'rgba(164, 87, 255, 0.5)',
  //NEW COLORS FOR SPORE - need to define light/dark here cause they are root colors now (different system)
  neutral1_dark: '#FFFFFF',
  neutral2_dark: '#9B9B9B',
  neutral3_dark: '#5E5E5E',
  surface1_dark: '#131313',
  surface2_dark: '#1B1B1B',
  surface3_dark: '#FFFFFF12',
  surface4_dark: '#FFFFFF20',
  surface5_dark: '#00000004',
  accent1_dark: '#00e673',
  accent2_dark: '#001a0d',
  neutral1_light: '#222222',
  neutral2_light: '#7D7D7D',
  neutral3_light: '#CECECE',
  surface1_light: '#FFFFFF',
  surface2_light: '#F9F9F9',
  surface3_light: '#22222212',
  surface4_light: '#FFFFFF64',
  surface5_light: '#00000004',
  accent1_light: '#00e673',
  accent2_light: '#e6fff2',
  success: '#40B66B',
  critical: '#FF5F52',
  scrim: 'rgba(0, 0, 0, 0.60)',
}

type Theme = typeof darkTheme

const commonTheme = {
  white: colors.white,
  black: colors.black,

  chain_1: colors.networkEthereum,
  chain_1_background: colors.networkEthereumSoft,
  chain_10: colors.networkOptimism,
  chain_10_background: colors.networkOptimismSoft,
  chain_137: colors.networkPolygon,
  chain_137_background: colors.networkPolygonSoft,
  chain_11155111: colors.networkEthereum,
  chain_11155111_background: colors.networkEthereumSoft,
  chain_3: colors.networkEthereum,
  chain_3_background: colors.networkEthereumSoft,
  chain_4: colors.networkEthereum,
  chain_4_background: colors.networkEthereumSoft,
  chain_420: colors.networkOptimism,
  chain_420_background: colors.networkOptimismSoft,
  chain_42161: colors.networkArbitrum,
  chain_42161_background: colors.networkArbitrumSoft,
  chain_421613: colors.networkArbitrum,
  chain_421613_background: colors.networkArbitrumSoft,
  chain_42220: colors.networkCelo,
  chain_42220_background: colors.networkCeloSoft,
  chain_43114: colors.networkAvalanche,
  chain_43114_background: colors.networkAvalancheSoft,
  chain_44787: colors.networkCelo,
  chain_44787_background: colors.networkCeloSoft,
  chain_5: colors.networkEthereum,
  chain_5_background: colors.networkEthereumSoft,
  chain_56: colors.networkBsc,
  chain_56_background: colors.networkBscSoft,
  chain_61: colors.networkClassic,
  chain_61_background: colors.networkClassicSoft,
  chain_63: colors.networkClassic,
  chain_63_background: colors.networkClassicSoft,
  chain_80001: colors.networkPolygon,
  chain_80001_background: colors.networkPolygonSoft,
  chain_8453: colors.networkBase,
  chain_8453_background: colors.networkBaseSoft,
  promotional: colors.networkClassic,
  promotional_background: colors.networkClassicSoft,

  brandedGradient: 'linear-gradient(139.57deg, #4DFFA6 5%, #80FFB3 95%);',
  promotionalGradient: colors.accent1_light,
}

export const darkTheme = {
  ...commonTheme,

  background: colors.black,

  neutral1: colors.neutral1_dark,
  neutral2: colors.neutral2_dark,
  neutral3: colors.neutral3_dark,

  surface1: colors.surface1_dark,
  surface2: colors.surface2_dark,
  surface3: colors.surface3_dark,
  surface4: colors.surface4_dark,
  surface5: colors.surface5_dark,

  accent1: colors.accent1_dark,
  accent2: colors.accent2_dark,
  success: colors.success,
  critical: colors.critical,
  scrim: colors.scrim,
}

export const lightTheme: Theme = {
  ...commonTheme,

  background: colors.white,

  neutral1: colors.neutral1_light,
  neutral2: colors.neutral2_light,
  neutral3: colors.neutral3_light,

  surface1: colors.surface1_light,
  surface2: colors.surface2_light,
  surface3: colors.surface3_light,
  surface4: colors.surface4_light,
  surface5: colors.surface5_light,

  accent1: colors.accent1_light,
  accent2: colors.accent2_light,

  success: colors.success,
  critical: colors.critical,
  scrim: colors.scrim,
}
